import { Button, Container } from "react-bootstrap"
import React, { useState } from "react";
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import Dropzone from 'react-dropzone';
import { MdUpload } from "react-icons/md";
interface Props {
    data: string[]
    saveFiles: (files: File[]) => void
}

const AdminGallery = ({ data, saveFiles }: Props) => {
    const [files, setFiles] = useState<File[]>([])
    const media = window.matchMedia("(max-width: 800px)").matches

    const splitArray = () => {
        return data.map(function (e, i) {
            return i % (media ? 3 : 5) === 0 ? data.slice(i, i + (media ? 3 : 5)) : null;
        }).filter(function (e) { return e; });
    }

    return (
        <Container>
            <h3 className="home-about__title mb-5">MANAGE GALLERY IMAGES</h3>
            <Dropzone onDrop={(files) => setFiles(files)}>
                {({ getRootProps, getInputProps }) => (
                    <section className="">
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <MdUpload size={35} color="white" />
                            <p className="font-weight-bold text-center">Drag 'n' drop an image or click to select a file</p>
                            <p style={{ fontWeight: 300 }}>Image files accepted (JPEG, PNG, GIF)</p>
                            <div className="dropzone-preview">
                                {files.map((val) => {
                                    return (
                                        <img className="w-20 mr-2 mb-2" src={URL.createObjectURL(val)} />
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                )}
                
            </Dropzone>
            <div className="w-100 d-flex justify-content-end">
                <Button disabled={files.length < 1} onClick={(e) => {
                    e.preventDefault()
                    saveFiles(files)
                    setFiles([])
                }} className="button mb-4" variant="primary" type="submit">
                    Upload
                </Button>
            </div>
            <div className="home-gallery">
                {splitArray().map((arr) => {
                    return (
                        <Row className="m-0">
                            {arr?.map((link) => {
                                return (
                                    <Col style={{ backgroundImage: `url(${link})` }} className="home-gallery__square"></Col>
                                )
                            })}
                        </Row>
                    )
                })}
            </div>
        </Container>
    )
}

export default AdminGallery