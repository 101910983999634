import { Container } from "react-bootstrap"
import React, { useState } from "react";
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Job } from "../App";
import JobItem from "./JobItem";

interface Props {
    saveData: (job: Job) => void
    vacancies: Job[]
}

const AdminVacancies = ({saveData, vacancies}: Props) => {
    const [name, setName] = useState<string>("")
    const [desc, setDesc] = useState<string>("")
    const [hours, setHours] = useState<string>("")
    const [salary, setSalary] = useState<string>("")
    return (
        <Container>
            <h3 className="home-about__title mb-5">MANAGE VACANCIES</h3>
            <Row>
                <Col sm={6}>
                    <Form className="mb-4">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className="form-label">Job title:</Form.Label>
                            <Form.Control value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Enter job title..." />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicMessage">
                            <Form.Label className="form-label">Job description:</Form.Label>
                            <Form.Control value={desc} onChange={(e) => setDesc(e.currentTarget.value)} as="textarea" rows={3} placeholder="Enter job description..." />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className="form-label">Working hours:</Form.Label>
                            <Form.Control value={hours} onChange={(e) => setHours(e.currentTarget.value)} placeholder="Enter working hours..." />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className="form-label">Salary:</Form.Label>
                            <Form.Control value={salary} onChange={(e) => setSalary(e.currentTarget.value)} placeholder="Enter salary..." />
                        </Form.Group>
                        <div className="w-100 d-flex justify-content-start">
                            <Button onClick={(e) => {
                                e.preventDefault()
                                saveData({desc, hours, salary, title: name})
                                setName("")
                                setDesc("")
                                setHours("")
                                setSalary("")
                            }} className="button" variant="primary" type="submit">
                                Upload
                            </Button>
                        </div>
                    </Form>
                </Col>
                <Col sm={6}>
                {vacancies.slice(0).reverse().map((job) => <JobItem button={false} data={job}/>)}
                </Col>
            </Row>
        </Container>
    )
}

export default AdminVacancies