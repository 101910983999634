interface Props {
    image: string
    title: string
}

const Discover = ({image, title}: Props) => {

    return (
        <div className="discover">
            <div className="discover__image" style={{backgroundImage: `url(${image})`}}></div>
            <p className="discover__title">{title}</p>
        </div>
    )
}

export default Discover