import { Container } from "react-bootstrap"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import Template from "../components/Page"
import ImageViewer from 'react-simple-image-viewer';
import { useCallback, useState } from "react";

interface Props {
    urls: string[]
}

const Gallery = ({ urls }: Props) => {
    const media = window.matchMedia("(max-width: 800px)").matches
    const [currentImage, setCurrentImage] = useState("");
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const splitArray = () => {
        return urls.map(function (e, i) {
            return i % 5 === 0 ? urls.slice(i, i + 5) : null;
        }).filter(function (e) { return e; });
    }
    const openImageViewer = useCallback((link: string) => {
        setCurrentImage(link);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage("");
        setIsViewerOpen(false);
    };

    return (
        <Template page="Gallery">
            {isViewerOpen &&
                        <ImageViewer
                        src={[currentImage]}
                        currentIndex={0}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
            }
            <div className={`home-gallery p-4 ${!media && "pt-7"}`}>
                {media ?
                    urls.map((url) => {
                        return (
                            <Col style={{ backgroundImage: `url(${url})` }} className="home-gallery__square home-gallery__square--page"></Col>
                        )
                    })
                    :

                    splitArray().map((arr) => {
                        return (
                            <Row className="m-0">
                                {arr?.map((link) => {
                                    return (
                                        <Col onClick={() => openImageViewer(link)} style={{ backgroundImage: `url(${link})` }} className="home-gallery__square"></Col>
                                    )
                                })}
                            </Row>
                        )
                    })

                }
            </div>
        </Template>
    )
}

export default Gallery