import head from "../images/fox.png"
import headOnly from "../images/head.png"
import React, { useState } from "react"
import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare } from "react-icons/fa"
import { Link } from "react-router-dom"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdMenu } from "react-icons/md"

interface Props {
    children: React.ReactNode
    page: string
}

const Template = ({ children, page }: Props) => {
    const media = window.matchMedia("(max-width: 800px)").matches
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="page">
            {media && <MdMenu onClick={() => handleShow()} className="page__icon" color="white" size={65} />}
            {media &&
                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton closeVariant="white">
                        <Offcanvas.Title>
                            <img className="page-nav__head" src={head} />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                    <Link to={"/"} className={`page-nav__link ${page === "Home" && "page-nav__link--active"}`}>Home</Link>
                    <Link to={"/about"} className={`page-nav__link ${page === "About" && "page-nav__link--active"}`}>About</Link>
                    <Link to={"/events"} className={`page-nav__link ${page === "Events" && "page-nav__link--active"}`}>Events</Link>
                    <Link to={"/gallery"} className={`page-nav__link ${page === "Gallery" && "page-nav__link--active"}`}>Gallery</Link>
                    <Link to={"/vacancies"} className={`page-nav__link ${page === "Vacancies" && "page-nav__link--active"}`}>Vacancies</Link>
                    <Link to={"/contact"} className={`page-nav__link ${page === "Contact" && "page-nav__link--active"}`}>Contact Us</Link>
                    </Offcanvas.Body>
                </Offcanvas>
            }
            <div className="page-nav">
                <img className="page-nav__head" src={head} />
                <ul className="d-flex flex-row m-0">
                    <Link to={"/"} className={`page-nav__link ${page === "Home" && "page-nav__link--active"}`}>Home</Link>
                    <Link to={"/about"} className={`page-nav__link ${page === "About" && "page-nav__link--active"}`}>About</Link>
                    <Link to={"/events"} className={`page-nav__link ${page === "Events" && "page-nav__link--active"}`}>Events</Link>
                    <Link to={"/gallery"} className={`page-nav__link ${page === "Gallery" && "page-nav__link--active"}`}>Gallery</Link>
                    <Link to={"/vacancies"} className={`page-nav__link ${page === "Vacancies" && "page-nav__link--active"}`}>Vacancies</Link>
                    <Link to={"/contact"} className={`page-nav__link ${page === "Contact" && "page-nav__link--active"}`}>Contact Us</Link>
                </ul>
                <div className="d-flex flex-row align-center">
                    <FaFacebookSquare style={{ cursor: "pointer" }} onClick={() => window.location.href = "https://www.facebook.com/foxintheboxbar/"} className="mr-1" color="white" size={24} />
                    <FaInstagramSquare style={{ cursor: "pointer" }} onClick={() => window.location.href = "https://www.instagram.com/foxintheboxbar"} className="" color="white" size={24} />
                </div>
            </div>
            <div>
                {children}
            </div>
            <div className="page-footer">
                <img src={headOnly} className="page-footer__logo mb-3" />
                <div className="d-flex flex-row align-center mb-3">
                    <FaFacebookSquare style={{ cursor: "pointer" }} onClick={() => window.location.href = "https://www.facebook.com/foxintheboxbar/"} className="mr-1" color="white" size={24} />
                    <FaInstagramSquare style={{ cursor: "pointer" }} onClick={() => window.location.href = "https://www.instagram.com/foxintheboxbar"} className="" color="white" size={24} />
                </div>
                <ul className={`d-flex flex-row m-0 ${media && "flex-column p-0"}`}>
                    <Link to={"/"} className={`page-nav__link page-footer__link`}>Home</Link>
                    <Link to={"/about"} className={`page-nav__link page-footer__link`}>About</Link>
                    <Link to={"/events"} className={`page-nav__link page-footer__link`}>Events</Link>
                    <Link to={"/gallery"} className={`page-nav__link page-footer__link`}>Gallery</Link>
                    <Link to={"/vacancies"} className={`page-nav__link page-footer__link`}>Vacancies</Link>
                    <Link to={"/contact"} className={`page-nav__link page-footer__link`}>Contact Us</Link>
                    <Link to={"/login"} className={`page-nav__link page-footer__link`}>Login</Link>
                </ul>
            </div>
        </div>
    )
}

export default Template