import Template from "../components/Page"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import image from "../images/FITB/333710493_597508935558860_6765136689561709708_n.jpg"
import IconLink from "../components/IconLink"
import spirit from "../images/spirit.jpg"
import { useEffect, useState } from "react"
import corona from "../images/corona.jpg"
import cocktails from "../images/cocktails.jpg"
import { MdMail, MdPhone } from "react-icons/md"
import { Document, Page, pdfjs } from "react-pdf";
import menu from "../images/DrinksMenu.pdf"
import {BsChevronCompactRight, BsChevronCompactLeft} from "react-icons/bs"
const About = () => {
    const [num, setNum] = useState<number>(1)

    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});
    const media = window.matchMedia("(max-width: 800px)").matches

    const nextPage = () => {
        if(num === 8) {
            setNum(1)
        } else {
            setNum(num + 1)
        }
    }

    const prevPage = () => {
        if(num === 1) {
            setNum(8)
        } else {
            setNum(num - 1)
        }
    }
    return (
        <Template page="About">
            <div className="about">
                <div className="about-background">
                    <Container>
                        <h2 className="about__title">ABOUT FOX IN THE BOX</h2>

                        <Row>
                            <Col sm={8} className="">
                                <p className="about-background__text mb-2">
                                    Welcome to Fox In The Box! We are a vibrant and lively establishment that prides itself on providing exceptional service, delicious cocktails, and a fun atmosphere for all. Our bar is the perfect place to unwind after a long day at work, catch up with friends, or celebrate a special occasion. We offer a wide selection of beer, wine, and spirits.
                                </p>
                                <p className="about-background__text mb-4">
                                    So come on in, grab a drink, and join us for a night of fun and excitement at Fox In The Box. We can't wait to see you!
                                </p>
                                <div className="d-flex flex-row align-items-center">
                                    <IconLink link="mailto:Info@FoxInTheBox.co.uk" className="mr-2" icon={<MdMail size={26} color="#B8D1AC" />} text="Info@FoxInTheBox.co.uk" />
                                    <IconLink link="tel:01246291818" icon={<MdPhone size={26} color="#B8D1AC" />} text="01246291818" />
                                </div>
                            </Col>
                            <Col sm={4} className={""}>
                                <img className="about-background__image" src={image} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="about-menu">

                    <Container>
                        <h2 className="about__title">DRINKS MENU</h2>
                        <Row>
                            <Col sm={4}>
                                <div className="w-100 d-flex flex-column">
                                    <div style={{ backgroundImage: `url(${spirit})` }} className="about-menu__image"></div>
                                    <div style={{ backgroundImage: `url(${cocktails})` }} className="about-menu__image"></div>
                                    <div style={{ backgroundImage: `url(${corona})` }} className="about-menu__image"></div>
                                </div>
                            </Col>
                            <Col sm={8}>
                                <Document className="pdfView" renderMode="canvas" file={menu}>
                                    <BsChevronCompactRight onClick={() => nextPage()} size={50} color="white" className="pdfView__right" />
                                    <BsChevronCompactLeft onClick={() => prevPage()} size={50} color="white" className="pdfView__left" />

                                    <Page scale={media ? 1 : 5} pageNumber={num} />
                                </Document>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Template>
    )
}

export default About