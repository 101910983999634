import Template from "../components/Page"
import logo from "../images/fox-text.png"
import { BsChevronCompactDown } from "react-icons/bs"
import { MdMail, MdPhone } from "react-icons/md"
import Discover from "../components/Discover"
import corona from "../images/corona.jpg"
import cocktail from "../images/cocktails.jpg"
import spirit from "../images/spirit.jpg"
import space from "../images/space.jpg"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import about from "../images/FITB/318109535_228433262850792_6938985797825236694_n.jpg"
import IconLink from "../components/IconLink"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useCallback, useEffect, useState } from "react"
import ImageViewer from 'react-simple-image-viewer';

import React from "react"

interface Props {
    urls: string[]
    getList: () => void
}

const Home = ({ urls, getList }: Props) => {
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [currentImage, setCurrentImage] = useState("");
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [message, setMessage] = useState<string>("")
    const media = window.matchMedia("(max-width: 800px)").matches

    const splitArray = () => {
        return urls.map(function (e, i) {
            return i % (media ? 3 : 5) === 0 ? urls.slice(i, i + (media ? 3 : 5)) : null;
        }).filter(function (e) { return e; });
    }

    const openImageViewer = useCallback((link: string) => {
        setCurrentImage(link);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage("");
        setIsViewerOpen(false);
    };

    return (
        <Template page="Home">
            {isViewerOpen &&
                        <ImageViewer
                        src={[currentImage]}
                        currentIndex={0}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
            }
            <div className="home-landing">
                <div className="home-landing-circle">
                    <img className="home-landing-circle__logo" src={logo} />
                </div>
                <div className="home-landing-scroll">
                    <p className="m-0">Scroll for information</p>
                    <BsChevronCompactDown className="home-landing-scroll__icon" size={40} color="white" />
                </div>
            </div>
            <div className="home-discover">
                <h2 className="home-discover__title">DISCOVER OUR RANGE OF OFFERINGS</h2>
                <p className="home-discover__subtitle">Amazing range or beers, spirits and cocktails</p>
                <div className={`d-flex flex-row justify-content-between ${media && "flex-column align-items-center"}`}>
                    <Discover image={corona} title={"Beers & Lagers"} />
                    <Discover image={spirit} title={"Range of Spirits"} />
                    <Discover image={cocktail} title={"Handmade Cocktails"} />
                    <Discover image={space} title={"Large Event Space"} />
                </div>
            </div>
            <div className="home-about">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <h3 className="home-about__title">ABOUT FOX IN THE BOX</h3>
                            <img src={about} className="home-about__image mr-2" />
                            <p className="home-about__text">
                                Welcome to Fox In The Box! We are a vibrant and lively establishment located in Dronfield that prides itself on providing exceptional service, delicious cocktails, and a fun atmosphere for all. Our bar is the perfect place to unwind after a long day at work, catch up with friends, or celebrate a special occasion. We offer a wide selection of beer, wine, and spirits.
                            </p>
                            <p className="home-about__text mb-5">
                                We also host a range of events throughout the year, from live music performances and trivia nights to holiday-themed parties and charity fundraisers. And if you're looking for a private event space, we've got you covered. Our team works closely with event planners and hosts to ensure that every detail is taken care of, from food and beverage service to decorations and entertainment.
                            </p>
                            <h3 className="mb-5" style={{ color: "white", fontSize: 26 }}>“Amazing venue and great staff. Made our day very special”</h3>
                            {!media &&
                                <div className={`d-flex flex-row align-items-start ${media && "mb-4 flex-column"}`}>
                                    <IconLink link="mailto:Info@TheFoxInTheBox.co.uk" className={`${media ? "mb-2" : "mr-2"}`} icon={<MdMail size={26} color="#B8D1AC" />} text="Info@TheFoxInTheBox.co.uk" />
                                    <IconLink link="tel:01246291818" icon={<MdPhone size={26} color="#B8D1AC" />} text="01246291818" />
                                </div>
                            }
                        </Col>
                        <Col sm={4}>
                            <h3>WHATS HAPPENING</h3>
                            <div id="fb-root"></div>
                            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ffoxintheboxbar%2F&tabs=timeline&width=360&height=580&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=6019558798137045" width="360" height="580" style={{ border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe></Col>
                    </Row>
                </Container>
            </div>
            {urls.length > 0 &&
                <div className="home-gallery">
                    {splitArray().map((arr) => {
                        return (
                            <Row className="m-0">
                                {arr?.map((link) => {
                                    return (
                                            <Col onClick={() => openImageViewer(link)} style={{ backgroundImage: `url(${link})` }} className="home-gallery__square"></Col>
                                    )
                                })}
                            </Row>
                        )
                    })}
                </div>
            }
            <div className="home-contact">
                <Container>
                    <Row>
                        <Col>
                            <h3 className="home-about__title">CONTACT US</h3>
                            <Form className="mb-4">
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label className="form-label">Full name:</Form.Label>
                                    <Form.Control placeholder="Enter full name..." />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="form-label">Email address:</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicMessage">
                                    <Form.Label className="form-label">Message:</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter message..." />
                                </Form.Group>
                                <div className="w-100 d-flex justify-content-start">
                                    <Button className="button" variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                            <div className="d-flex flex-column">
                                <IconLink link="mailto:Info@TheFoxInTheBox.co.uk" className="mb-2" icon={<MdMail size={26} color="#B8D1AC" />} text="Info@TheFoxInTheBox.co.uk" />
                                <IconLink link="tel:01246291818" icon={<MdPhone size={26} color="#B8D1AC" />} text="01246291818" />
                            </div>
                        </Col>
                        {!media &&
                            <Col>
                                <div className="home-contact-iframe">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2384.2878143325056!2d-1.4528222840246268!3d53.302291585711046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48798343c00b14d5%3A0x106b0620d8bccd0c!2sFox%20in%20the%20Box%20Bar%20and%20Function%20Rooms!5e0!3m2!1sen!2suk!4v1680594215411!5m2!1sen!2suk" width="100%" height="500" loading="lazy"></iframe>
                                </div>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Template>
    )
}

export default Home