import Button from 'react-bootstrap/Button';
import { Job } from '../App';

interface Props {
    data: Job
    button?: boolean
}

const JobItem = ({ data, button = true }: Props) => {
    return (
        <div className="jobItem">
            <p className="jobItem__title">{data.title.toLocaleUpperCase()}</p>
            <p className="jobItem__desc">{data.desc}</p>
            <p className="jobItem__sub">Working Hours:</p>
            <p className="jobItem__text">{data.hours}</p>
            <p className="jobItem__sub">Estimated Salary:</p>
            <p className="jobItem__text">{data.salary}</p>
            {button &&
                <div className="w-100 d-flex justify-content-end">
                    <Button onClick={() => window.location.href = `mailto:info@foxintthebox.co.uk?subject=${data.title} job application`} className="button" variant="primary" type="submit">
                        Apply
                    </Button>
                </div>
            }
        </div>
    )
}

export default JobItem