import logo from "../images/fox.png";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const navigate = useNavigate();
    const media = window.matchMedia("(max-width: 800px)").matches


    const auth = getAuth();
    const signIn = (email: string, password: string) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                navigate("/admin")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                toast.error(errorMessage, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
            });
    }

    return (
        <div className="login">
            <div className="login-container">
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <img className="login-container__logo mb-4" src={logo} />
                <Form className="w-100">
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Label className="form-label">Email address:</Form.Label>
                        <Form.Control value={email} onChange={(val) => setEmail(val.currentTarget.value)} type="email" placeholder="Enter email..." />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="form-label">Password:</Form.Label>
                        <Form.Control value={password} onChange={(val) => setPassword(val.currentTarget.value)} type="password" placeholder="Enter password..." />
                    </Form.Group>
                    <div className="w-100 d-flex justify-content-end">
                        <Button onClick={(e) => {
                            e.preventDefault()
                            signIn(email, password)
                        }} className="button" variant="primary" type="submit">
                            Sign in
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default Login