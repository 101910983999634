import { Container } from "react-bootstrap"
import React, { useState } from "react";
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import EventItem from "./EventItem"
import Button from 'react-bootstrap/Button';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { Event } from "../App";

interface Props {
    saveEvent: (title: string, date: string, desc: string) => void
    events: Event[]
}

const AdminEvents = ({saveEvent, events}: Props) => {
    const [startDate, setStartDate] = useState(new Date());
    const [name, setName] = useState<string>("")
    const [desc, setDesc] = useState<string>("")
    registerLocale('en-GB', enGB)
    setDefaultLocale('en-GB');
    
    return (
        <Container>
            <h3 className="home-about__title mb-5">MANAGE UPCOMING EVENTS</h3>
            <Row>
                <Col sm={8}>
                    <Form className="mb-4">
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className="form-label">Event name:</Form.Label>
                            <Form.Control value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Enter event name..." />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="form-label">Event date:</Form.Label>
                            <DatePicker locale="en-GB" className="form-control" selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicMessage">
                            <Form.Label className="form-label">Description:</Form.Label>
                            <Form.Control value={desc} onChange={(e) => setDesc(e.currentTarget.value)} as="textarea" rows={3} placeholder="Enter description..." />
                        </Form.Group>
                        <div className="w-100 d-flex justify-content-start">
                            <Button onClick={(e) => {
                                e.preventDefault()
                                saveEvent(name, startDate.toISOString(), desc)
                                setName("")
                                setDesc("")
                                setStartDate(new Date())
                            }} className="button" variant="primary" type="submit">
                                Upload
                            </Button>
                        </div>
                    </Form>
                </Col>
                <Col sm={4}>
                    {events.slice(0).reverse().map((event) => <EventItem desc={event.desc} title={event.title} date={event.date} />)}
                </Col>
            </Row>
        </Container>
    )
}

export default AdminEvents