import Template from "../components/Page"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import image from "../images/FITB/318109535_228433262850792_6938985797825236694_n.jpg"
import { MdMail, MdPhone } from "react-icons/md"
import IconLink from "../components/IconLink"
import EventItem from "../components/EventItem"
import { Event } from "../App"

interface Props {
    events: Event[]
}

const Events = ({ events }: Props) => {

    return (
        <Template page="Events">
            <div className="about">
                <div className="about-background">
                    <Container>
                        <h2 className="about__title">WHATS HAPPENING AT FOX IN THE BOX</h2>

                        <Row>
                            <Col sm={8} className="">
                                <p className="about-background__text mb-2">
                                    At Fox In The Box, we host a variety of events throughout the year that are designed to bring people together and create a fun and festive atmosphere. Whether you're looking for a place to celebrate a special occasion or just want to unwind with friends after work, we have something for everyone.
                                </p>
                                <p className="about-background__text mb-4">
                                    So whether you're looking for a night out on the town or planning a special event, come join us at our bar and experience the fun and excitement that our events have to offer!                                </p>
                                <div className="d-flex flex-row align-items-center">
                                    <IconLink link="mailto:Info@FoxInTheBox.co.uk" className="mr-2" icon={<MdMail size={26} color="#B8D1AC" />} text="Info@FoxInTheBox.co.uk" />
                                    <IconLink link="tel:01246291818" icon={<MdPhone size={26} color="#B8D1AC" />} text="01246291818" />
                                </div>
                            </Col>
                            <Col sm={4} className={""}>
                                <img className="about-background__image" src={image} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="about-menu">

                    <Container>
                        <h2 className="about__title">EVENT INFORMATION</h2>
                        <Row>
                            <Col sm={5}>
                                {events.slice(0).reverse().slice(0, 4).map((event) => <EventItem desc={event.desc} title={event.title} date={event.date} />)}
                            </Col>
                            <Col sm={7}>
                                <h2 className="about__title">HIRING FOR A FUNCTION</h2>
                                <p className="about-background__text mb-4">
                                    Please note that all bookings are secured by a £100 deposit which is fully refundable after the event provided that no damages occur.
                                </p>
                                <p className="eventItem-header__title">FUNCTION ROOM</p>
                                <p className="events__heading">The function room has a max capacity of 60 people</p>
                                <ul className="events-list">
                                    <li>Daytime rate: £65 for 3 hours</li>
                                    <li>Evening rate: £100</li>
                                </ul>
                                <p className="eventItem-header__title">MAIN BAR AREA</p>
                                <p className="events__heading">The main bar area has a max capacity of 120 people</p>
                                <ul className="events-list">
                                    <li>Daytime rate: £100 for 4 hours exclusive</li>
                                    <li>Evening rate: £200 non exclusive</li>
                                </ul>
                                <p className="eventItem-header__title">HALF OF THE MAIN BAR AREA</p>
                                <p className="events__heading">Half of the main bar area has a max capacity of 60 people</p>
                                <ul className="events-list">
                                    <li>Daytime rate: £65 for 3 hours</li>
                                </ul>
                                <p className="eventItem-header__title">EXCLUSIVE HIRE OF THE ENTIRE VENUE</p>
                                <p className="events__heading">The entire venue has a max capacity of 180 people</p>
                                <ul className="events-list">
                                    <li>Evening rate: £300</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Template>
    )
}

export default Events