import { ReactNode } from "react"

interface Props {
    icon: ReactNode
    text: string 
    link?: string
    className?: string;
}

const IconLink = ({icon, text, link, className}: Props) => {
    return (
        <a href={link} className={`d-flex flex-row align-items-center iconLink ${className}`}>
            {icon}
            <span>{text}</span>
        </a>
    )
}

export default IconLink