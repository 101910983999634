import moment from "moment"

interface Props {
    title: string
    desc: string
    date: string
}

const EventItem = ({title, desc, date}: Props) => {

    const d = moment(date).utc()
    return (
        <div className="eventItem">
            <div className="eventItem-header">
                <div className="eventItem-header-date">
                    <p className="eventItem-header-date__month">{d.format("MMM").toLocaleUpperCase()}</p>
                    <p className="eventItem-header-date__num">{d.format("DD")}</p>
                </div>
                <p className="eventItem-header__title">{title.toLocaleUpperCase()}</p>
            </div>
            <p className="eventItem__text">{desc}</p>
        </div>
    )
}

export default EventItem