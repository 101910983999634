import Template from "../components/Page"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import IconLink from "../components/IconLink"
import { MdMail, MdPhone } from "react-icons/md"
import image from "../images/FITB/334930219_745817513816168_1477527526007862630_n.jpg"
import JobItem from "../components/JobItem"
import { Job } from "../App"

interface Props {
    jobs: Job[]
}

const Vacancies = ({ jobs }: Props) => {

    function chunkArray(arr: Job[], n: number) {
        var chunkLength = Math.max(arr.length / n, 1);
        var chunks = [];
        for (var i = 0; i < n; i++) {
            if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
        }
        return chunks;
    }

    return (
        <Template page="Vacancies">
            <div className="about">
                <div className="about-background">
                    <Container>
                        <h2 className="about__title">JOBS & VANCANCIES</h2>
                        <Row>
                            <Col sm={8} className="">
                                <p className="about-background__text mb-2">
                                    Fox In The Box  is known for its exceptional service, creative cocktails, and lively atmosphere. We are committed to providing our customers with an unforgettable experience every time they visit, and we do this by hiring passionate employees.
                                </p>
                                <p className="about-background__text mb-4">
                                    To apply for a role please select "Apply" on your desired role below and attach your CV.
                                </p>
                                <div className="d-flex flex-row align-items-center">
                                    <IconLink link="mailto:Info@FoxInTheBox.co.uk" className="mr-2" icon={<MdMail size={26} color="#B8D1AC" />} text="Info@FoxInTheBox.co.uk" />
                                    <IconLink link="tel:01246291818" icon={<MdPhone size={26} color="#B8D1AC" />} text="01246291818" />
                                </div>
                            </Col>
                            <Col sm={4} className={""}>
                                <img className="about-background__image" src={image} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="about-menu">

                    <Container>
                        <h2 className="about__title">{jobs.length > 0 ? "AVAILABLE VACANCIES" : "NO VACANCIES AVAILABLE"}</h2>
                        {jobs.length > 0 &&
                            <Row>
                                <Col sm={6}>
                                    {chunkArray(jobs, 2)[0].slice(0).reverse().map((job) => <JobItem button={true} data={job} />)}
                                </Col>
                                <Col sm={6}>
                                    {chunkArray(jobs, 2)[1].slice(0).reverse().map((job) => <JobItem button={true} data={job} />)}
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
            </div>
        </Template>
    )
}

export default Vacancies