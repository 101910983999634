import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../images/fox.png"
import { MdCalendarMonth, MdPersonAdd, MdOutlineAddPhotoAlternate, MdExitToApp } from "react-icons/md"
import AdminEvents from "../components/AdminEvents";
import { FirebaseApp } from "firebase/app";
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { Event, Job } from "../App";
import AdminVacancies from "../components/AdminVacancies";
import { getStorage, ref, list, getDownloadURL } from "firebase/storage";
import AdminGallery from "../components/AdminGallery";
import { uploadBytes } from "firebase/storage";
import uuid from 'react-uuid';
import Offcanvas from 'react-bootstrap/Offcanvas';
import head from "../images/fox.png"
import { MdMenu } from "react-icons/md"

interface Props {
  app: FirebaseApp
}

const Admin = ({ app }: Props) => {
  const [page, setPage] = useState("events")
  const [events, setEvents] = useState<Event[]>([])
  const [jobs, setJobs] = useState<Job[]>([])
  const [urls, setUrls] = useState<string[]>([])
  const media = window.matchMedia("(max-width: 800px)").matches
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const auth = getAuth()
  const navigate = useNavigate();
  const db = getFirestore(app);
  if (!auth.currentUser) {
    navigate("/login")
  }

  const getEvents = async () => {
    const docRef = doc(db, "data", "events");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log(docSnap.data());
      setEvents(docSnap.data().list)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const getVacancies = async () => {
    const docRef = doc(db, "data", "vacancies");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log(docSnap.data());
      setJobs(docSnap.data().list)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const setEvent = async (title: string, date: string, desc: string) => {
    const docRef = doc(db, "data", "events");
    await updateDoc(docRef, {
      list: arrayUnion({ title, date, desc })
    });
    getEvents()

  }

  const setVacancies = async (job: Job) => {
    const docRef = doc(db, "data", "vacancies");
    await updateDoc(docRef, {
      list: arrayUnion(job)
    });
    getVacancies()
  }

  const listImages = async () => {
    // Create a reference under which you want to list
    const storage = getStorage();
    const listRef = ref(storage, 'Images');

    // Fetch the first page of 15.
    const imageList = await list(listRef);
    const temp: string[] = []
    imageList.items.map((item) => {
      const storage = getStorage();
      getDownloadURL(ref(storage, item.fullPath))
        .then((url) => {
          temp.push(url)
        }).then(() => setUrls([...temp]))
        .catch((error: any) => {
          console.log(error)
        });
    })
  }

  const storage = getStorage();
  const storageRef = ref(storage, 'Images');

  const uploadImage = (files: File[]) => {
    files.map(async (file) => {
      await uploadBytes(ref(storage, `/Images/${uuid()}-${file.name}`), file)
    })
    listImages()
  }

  useEffect(() => {
    getEvents()
    getVacancies()
    listImages()
  }, [])

  return (
    <div className="admin">
      {media && <MdMenu onClick={() => handleShow()} className="page__icon" color="white" size={65} />}
      {media &&
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton closeVariant="white">
            <Offcanvas.Title>
              <img className="page-nav__head" src={head} />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="d-flex flex-column align-items-center">
              <a onClick={() => {
                setPage("events")
                handleClose()
                }} className={`admin-menu__link mb-4 ${page === "events" && "admin-menu__link--bold"}`}><MdCalendarMonth size={22} /> Events</a>
              <a onClick={() => {
                setPage("vacancies")
                handleClose()
                }} className={`admin-menu__link mb-4 ${page === "vacancies" && "admin-menu__link--bold"}`}><MdPersonAdd size={22} /> Vacancies</a>
              <a onClick={() => {
                setPage("gallery")
                handleClose()
                }} className={`admin-menu__link mb-5 ${page === "gallery" && "admin-menu__link--bold"}`}><MdOutlineAddPhotoAlternate size={22} /> Gallery</a>
            </div>
            <div className="d-flex flex-column align-items-center">
            <Link to={"/"} className="admin-menu__link"><MdExitToApp size={22} /> Sign out</Link>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      }
      {!media &&
        <div className="admin-menu">
          <img src={logo} className="admin-menu__logo" />
          <div className="d-flex flex-column">
            <a onClick={() => setPage("events")} className={`admin-menu__link mb-5 ${page === "events" && "admin-menu__link--bold"}`}><MdCalendarMonth size={22} /> Events</a>
            <a onClick={() => setPage("vacancies")} className={`admin-menu__link mb-5 ${page === "vacancies" && "admin-menu__link--bold"}`}><MdPersonAdd size={22} /> Vacancies</a>
            <a onClick={() => setPage("gallery")} className={`admin-menu__link ${page === "gallery" && "admin-menu__link--bold"}`}><MdOutlineAddPhotoAlternate size={22} /> Gallery</a>
          </div>
          <Link to={"/"} className="admin-menu__link"><MdExitToApp size={22} /> Sign out</Link>
        </div>
      }
      <div className="admin-content">
        {page === "events" && <AdminEvents events={events} saveEvent={(title: string, date: string, desc: string) => setEvent(title, date, desc)} />}
        {page === "vacancies" && <AdminVacancies vacancies={jobs} saveData={(job) => setVacancies(job)} />}
        {page === "gallery" && <AdminGallery saveFiles={(files) => uploadImage(files)} data={urls} />}
      </div>
    </div>
  )
}

export default Admin