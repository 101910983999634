import Template from "../components/Page"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import image from "../images/FITB/333710493_597508935558860_6765136689561709708_n.jpg"
import IconLink from "../components/IconLink"
import spirit from "../images/spirit.jpg"
import { useEffect, useState } from "react"
import corona from "../images/corona.jpg"
import cocktails from "../images/cocktails.jpg"
import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';

import { MdMail, MdPhone } from "react-icons/md"
const Contact = () => {
    const media = window.matchMedia("(max-width: 800px)").matches

    return (
        <Template page="Contact">
            <div className="about">
                <div className="about-background">
                    <Container>
                        <h2 className="about__title">GET IN TOUCH WITH US</h2>

                        <Row>
                            <Col sm={8} className="">
                                <p className="about-background__text mb-4">
                                    If you would like to get in touch with us please call or email using the contact details below. Alternatively you can also send us a message directly by filling in the contact form below.
                                </p>
                                <p className="about-background__text">
                                    The Akademy Business Park<br />Callywhite Lane<br />Dronfield<br />S18 2XR
                                </p>
                                <div className="d-flex flex-row align-items-center">
                                    <IconLink link="mailto:Info@FoxInTheBox.co.uk" className="mr-2" icon={<MdMail size={26} color="#B8D1AC" />} text="Info@FoxInTheBox.co.uk" />
                                    <IconLink link="tel:01246291818" icon={<MdPhone size={26} color="#B8D1AC" />} text="01246291818" />
                                </div>
                            </Col>
                            <Col sm={4} className={""}>
                                <img className="about-background__image" src={image} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="about-menu">

                    <Container>
                        <h2 className="about__title">LEAVE US A MESSAGE</h2>
                        <Row>
                            <Col>
                                <Form className="mb-4">
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label className="form-label">Full name:</Form.Label>
                                        <Form.Control placeholder="Enter full name..." />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className="form-label">Email address:</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicMessage">
                                        <Form.Label className="form-label">Message:</Form.Label>
                                        <Form.Control as="textarea" rows={3} placeholder="Enter message..." />
                                    </Form.Group>
                                    <div className="w-100 d-flex justify-content-start">
                                        <Button className="button" variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                                <div className="d-flex flex-column">
                                    <IconLink link="mailto:Info@FoxInTheBox.co.uk" className="mr-2" icon={<MdMail size={26} color="#B8D1AC" />} text="Info@FoxInTheBox.co.uk" />
                                    <IconLink link="tel:01246291818" icon={<MdPhone size={26} color="#B8D1AC" />} text="01246291818" />
                                </div>
                            </Col>
                            {!media &&
                                <Col>
                                    <div className="home-contact-iframe">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2384.2878143325056!2d-1.4528222840246268!3d53.302291585711046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48798343c00b14d5%3A0x106b0620d8bccd0c!2sFox%20in%20the%20Box%20Bar%20and%20Function%20Rooms!5e0!3m2!1sen!2suk!4v1680594215411!5m2!1sen!2suk" width="100%" height="500" loading="lazy"></iframe>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Container>
                </div>
            </div>
        </Template>
    )
}

export default Contact