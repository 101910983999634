import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, list, getDownloadURL } from "firebase/storage";
import { useEffect } from "react"
import About from './pages/About';
import Gallery from './pages/Gallery';
import Events from './pages/Events';
import Vacancies from './pages/Vacancies';
import { pdfjs } from "react-pdf";
import Contact from './pages/Contact';
import Login from './pages/Login';
import Admin from './pages/Admin';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";

export interface Event {
  title: string;
  desc: string;
  date: string;
}

export interface Job {
  title: string;
  desc: string;
  hours: string;
  salary: string;
}

function App() {

  useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; });

  const firebaseConfig = {
    apiKey: "AIzaSyC-uUskmmGFWNOCAwnzIQYypO6J_arZFL4",
    authDomain: "foxinthebox-5517d.firebaseapp.com",
    projectId: "foxinthebox-5517d",
    storageBucket: "foxinthebox-5517d.appspot.com",
    messagingSenderId: "115925573511",
    appId: "1:115925573511:web:64581c05f1ceac4170fd22",
    measurementId: "G-BFDLM0BW2B"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);


  const [urls, setUrls] = React.useState<string[]>([])
  const [urls15, setUrls15] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [events, setEvents] = React.useState<Event[]>([])
  const [jobs, setJobs] = React.useState<Job[]>([])



  const getEvents = async () => {
    const docRef = doc(db, "data", "events");
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      console.log(docSnap.data());
      setEvents(docSnap.data().list)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
}

const getVacancies = async () => {
  const docRef = doc(db, "data", "vacancies");
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    console.log(docSnap.data());
    setJobs(docSnap.data().list)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

  const listImages = async () => {
    setLoading(true)
    // Create a reference under which you want to list
    const storage = getStorage();
    const listRef = ref(storage, 'Images');

    // Fetch the first page of 15.
    const imageList = await list(listRef);
    const temp: string[] = []
    imageList.items.map((item) => {
      const storage = getStorage();
      getDownloadURL(ref(storage, item.fullPath))
        .then((url) => {
          temp.push(url)
        }).then(() => setUrls([...temp]))
        .catch((error: any) => {
          console.log(error)
        });
    })
    setLoading(false)

  }

  const list15Images = async () => {
    setLoading(true)
    // Create a reference under which you want to list
    const storage = getStorage();
    const listRef = ref(storage, 'Images');

    // Fetch the first page of 15.
    const imageList = await list(listRef, { maxResults: 15 });
    const temp: string[] = []
    imageList.items.map((item) => {
      const storage = getStorage();
      getDownloadURL(ref(storage, item.fullPath))
        .then((url) => {
          temp.push(url)
        }).then(() => setUrls15([...temp]))
        .catch((error: any) => {
          console.log(error)
        });
    })
    setLoading(false)

  }

  useEffect(() => {
    listImages()
    list15Images()
    getEvents()
    getVacancies()
  }, [])


  if (loading) {
    return (<div></div>)
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home getList={() => list15Images()} urls={urls15} />} />
        <Route path='/about' element={<About />} />
        <Route path='/events' element={<Events events={events}/>} />
        <Route path='/vacancies' element={<Vacancies jobs={jobs}/>} />
        <Route path='/gallery' element={<Gallery urls={urls} />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/admin' element={<Admin app={app}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
